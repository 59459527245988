/*
* @readonly This file is generated dynamically. Change 'envs/{env}.ts' instead.
*/
import { Env } from "@storyverseco/svs-types";
// import { baseGoerli, goerli } from "@wagmi/chains";
import { baseSepolia, sepolia } from "@alchemy/aa-core";
import { BASE_SEPOLIA_ENTRYPOINT_ADDRESS } from 'config/consts';

export const env = {
  l1Chain: sepolia,
  l2Chain: baseSepolia as any,
  assetsApiPrefix: '',
  authBaseUrl: 'https://auth.beta.pnk.one',
  chainIds: [5] as number[],
  env: 'devProd' as Env,
  foundersPassContract: '',
  gameUrl: 'https://view.ff.gemz.fun?iframed=true',
  mbaasApiKey: '',
  mbaasBaseUrl: '',
  contractName: 'gemz_fake_prod',
  mediaServiceBaseUrl: '',
  persistPlotServerPrefix: '',
  persistStoryServerPrefix: '',
  pipelineBaseUrl: 'https://pipeline.ff.gemz.fun',
  plotsBaseURI: '',
  serviceProxyUrl: '',
  templateApiPrefix: '',
  walletConnectId: '',
  walletConnectorVersion: 1 as 1 | 2,
  privyAppId: 'clpu9552h00ghl30iqgxfppuq',
  oneSignalAppId: 'fd01f180-1074-44c3-a8a3-dde79f843bf9', // -> ff.gemz.fun
  urls: {
    rpc: {
      alchemy: 'https://base-sepolia.g.alchemy.com/v2/Tx_Qwmy8UD7ZCTKoQt0rOeBpFdKiEx_2',
      coinbase: 'https://api.developer.coinbase.com/rpc/v1/base-sepolia/_lIXH-xvn2qABZn1z1qmdim09LbrNJ8n',
    }
  },
  entryPointAddress: BASE_SEPOLIA_ENTRYPOINT_ADDRESS,
}
